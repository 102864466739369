import { useCallback } from 'react';

import AnalyticsSection from '../Charts/AnalyticsSection';
import LineChart from '../Charts/LineChart';
import LineChartSection from '../Charts/LineChartSection';
import RateCard from '../Charts/RateCard';
import TotalCard from '../Charts/TotalCard';

import DayTab, { Days } from './DayTab';
import Filters from './Filters';
import Notification from './Notification';
import PieChart from './PieChart';
import StateCard, { Type } from './StateCard';

export default function OverviewMetrics() {
  const handlePieDays = useCallback((day: Days) => {
    // it's okay :)
    console.log(day);
  }, []);

  return (
    <div style={{ marginBottom: 24 }} className="card card--shadow">
      <Notification />
      <Filters />
      <section className="loans-overview__metric-section">
        <div style={{ padding: '24px 16px 0 26px' }} className="card">
          <h3 className="loans-overview__metric-section__title">Reasons for loans</h3>
          <DayTab onChange={handlePieDays} />
          <PieChart />
        </div>

        <div>
          <LineChartSection title="Total loans created" value={316} percent={25} />
          <TotalCard title="Total loans repaid" value={78} percent={6} />
        </div>

        <div>
          <LineChartSection title="Total loans accepted" value={124} percent={12} />
          <RateCard title="Delinquency rate" percent={0} />
        </div>
      </section>
    </div>
  );
}
