import { createAsyncThunk } from '@reduxjs/toolkit';

import { LoanAnalyticsDto } from '..';
import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export type LoansSearchQueryDto = {
  reason?: string;
  dateStart?: string;
  dateEnd?: string;
};

export interface LoansSearchQuery {
  reason: string;
  periodTime: string[];
  types: string[];
}

export const getLoansAnalytics = createAsyncThunk<
  LoanAnalyticsDto,
  LoansSearchQuery,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('getLoansAnalytics', async (payload, { rejectWithValue }) => {
  try {
    const { periodTime, reason, types } = payload;
    const { data } = await api.get<LoanAnalyticsDto>('/analytics/loans-analytics', {
      params: <LoansSearchQueryDto>{
        dateStart: periodTime[0],
        dateEnd: periodTime[1],
        reason: reason.toLocaleLowerCase(),
        types,
      },
    });

    return data;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
