import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { RootState } from '../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon } from '../../../shared';
import { Amplitude, AmplitudeEvents } from '../../../shared/services/amplitude';
import { FlowTypes } from '../../auth/store';
import { setToolkitMenuOpen } from '../../dashboard/store/dashboardSlice';
import { getSftpSettings } from '../store/actions/getSftpSettings';

interface MenuItem {
  name: string;
  name2?: string;
  path: string;
  event?: AmplitudeEvents;
}

interface MenuCategory {
  name: string;
  items: MenuItem[];
}

const menu: MenuCategory[] = [
  {
    name: 'Webhooks',
    items: [
      {
        name: 'Configuration',
        name2: 'Subscription',
        path: '/developer-tools/webhooks/configuration',
        event: AmplitudeEvents.webhookIntegrationSelected,
      },
    ],
  },
  {
    name: 'SFTP',
    items: [
      {
        name: 'Overview',
        path: '/developer-tools/sftp/overview',
        event: AmplitudeEvents.sftpConfiguration,
      },
    ],
  },
  {
    name: 'SFTP',
    items: [
      {
        name: 'Overview',
        path: '/developer-tools/sftp/overview',
        event: AmplitudeEvents.sftpConfiguration,
      },
      {
        name: 'Configuration',
        path: '/developer-tools/sftp/configuration',
        event: AmplitudeEvents.sftpConfiguration,
      },
      {
        name: 'File Upload',
        path: '/developer-tools/sftp/file-upload',
        event: AmplitudeEvents.sftpConfiguration,
      },
    ],
  },
];

interface DevToolsMenuProps {
  openNavBar: boolean;
  toolkitMenuOpen: boolean;
}

const DevToolsMenu = ({ openNavBar, toolkitMenuOpen }: DevToolsMenuProps) => {
  const dispatch = useAppDispatch();
  const { sftpSetting } = useAppSelector((state: RootState) => state.devTools);
  const { partner } = useAppSelector((state) => state.auth, shallowEqual);
  const location = useLocation();

  let menuItem = [];
  if (location.pathname.includes('webhooks/configuration')) {
    menuItem = [menu[0]];
  } else if (sftpSetting) {
    menuItem = [menu[2]];
  } else {
    menuItem = [menu[1]];
  }

  useEffect(() => {
    // @ts-ignore
    if (partner?.details.flowType !== FlowTypes.LOAN_CREATOR) dispatch(getSftpSettings());
  }, []);

  const handleClick = (item: MenuItem) => {
    if (item && item.event) {
      Amplitude.logEvent(item.event);
    }
  };

  const handleCloseMenu = () => {
    dispatch(setToolkitMenuOpen(false));
  };

  const { webhooks } = useAppSelector((state: RootState) => state.devTools);

  const isExistWebhooks = Boolean(webhooks && webhooks.length);

  const getClass = () => {
    let classes = '';
    if (toolkitMenuOpen && openNavBar) {
      classes = 'dev-tools-menu dev-tools-menu--active';
    } else if (openNavBar === false && toolkitMenuOpen) {
      classes = 'dev-tools-menu';
    } else {
      classes = 'dev-tools-menu dev-tools-menu--open';
    }
    return classes;
  };

  return (
    <div className={getClass()}>
      <div className="dev-tools-menu__wrapper">
        <button type="button" className="dev-tools-menu__close-button" onClick={handleCloseMenu}>
          <Icon name="cross" classes="dev-tools-menu__close-icon" />
        </button>
        {menuItem.map((category) => (
          <div className="dev-tools-menu__category" key={category?.name}>
            <div className="dev-tools-menu__category-title">{category?.name}</div>
            <ul className="dev-tools-menu__list">
              {category?.items?.map((item) => (
                <li className="dev-tools-menu__item" key={item?.name}>
                  <Icon name="nestedIcon" />
                  <NavLink
                    to={item.path}
                    className="dev-tools-menu__link"
                    activeClassName="dev-tools-menu__link--active"
                    onClick={() => handleClick(item)}
                  >
                    {isExistWebhooks && item?.name2 ? item?.name2 : item?.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="dev-tools-menu__line" />
      <div className="dev-tools-menu__handle-book">
        <Icon name="book-open" />
        <p className="dev-tools-menu__text">Developer Handbook</p>
        <a
          href="https://docs.zirtue.com/docs/developer-toolkit"
          target="_blank"
          rel="noopener noreferrer"
          className="dev-tools-menu__more"
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

export default DevToolsMenu;
