import React, { useCallback } from 'react';

import { LoanAnalyticsDto } from '../../store';
import LineChartSection from '../Charts/LineChartSection';
import RateCard from '../Charts/RateCard';
import TotalCard from '../Charts/TotalCard';
import PieChart from '../OverView/PieChart';

interface OverviewMetricsProps {
  loanAnalytics: LoanAnalyticsDto;
}
export default function OverviewMetrics({
  loanAnalytics: {
    totalLoansCreated,
    totalLoansAccepted,
    disbursement,
    totalLoansRepaid,
    delinquencyRate,
    borrowerTypes,
    reasonsForLoans,
    businessCategories,
  },
}: OverviewMetricsProps) {
  const getPieChartData = useCallback((data: { [key: string]: string | number }[], key: string) => {
    const labels: string[] = [];
    const values: number[] = [];
    data.forEach((item) => {
      const name = item[key] as string;
      const nameReplaces = name.replaceAll('_', ' ') as string;
      labels.push(nameReplaces.charAt(0).toUpperCase() + nameReplaces.slice(1));
      values.push(item.count as number);
    });

    return { labels, data: values };
  }, []);

  return (
    <div style={{ marginBottom: 24, padding: 0 }} className="card card--shadow">
      <section className="loans-overview__metric-section loans-overview__metric-section--analytics">
        <LineChartSection
          title="Total loans created"
          value={totalLoansCreated}
          percent={25}
          cardMinWidth={336}
        />
        <LineChartSection
          title="Total loans accepted"
          value={totalLoansAccepted}
          percent={25}
          cardMinWidth={336}
        />
        <LineChartSection
          title="Disbursements"
          value={disbursement}
          percent={25}
          cardMinWidth={336}
        />
      </section>
      <section
        style={{ marginTop: 16, paddingBottom: 40 }}
        className="loans-overview__metric-section loans-overview__metric-section--analytics"
      >
        <TotalCard title="Total loans repaid" value={totalLoansRepaid} percent={6} />
        <RateCard title="Delinquency rate" percent={delinquencyRate} />
      </section>
      <section
        style={{ marginTop: 16, padding: '24px 24px 40px 24px' }}
        className="loans-overview__metric-section"
      >
        {reasonsForLoans.length > 0 && (
          <div style={{ padding: '24px 16px 0 26px' }} className="card">
            <h3 className="loans-overview__metric-section__title">Reasons for loans</h3>
            <PieChart data={getPieChartData(reasonsForLoans, 'reasonsForLoan')} />
          </div>
        )}
        {borrowerTypes.length > 0 && (
          <div style={{ padding: '24px 16px 0 26px' }} className="card">
            <h3 className="loans-overview__metric-section__title">Borrower type</h3>
            <PieChart data={getPieChartData(borrowerTypes, 'borrowerType')} />
          </div>
        )}
        {businessCategories.length > 0 && (
          <div style={{ padding: '24px 16px 0 26px' }} className="card">
            <h3 className="loans-overview__metric-section__title">Business category</h3>
            <PieChart data={getPieChartData(businessCategories, 'businessCategory')} />
          </div>
        )}
      </section>
    </div>
  );
}
